import img1 from "../assets/photos/img1.jpg";
import img2 from "../assets/photos/img2.jpg";
import img3 from "../assets/photos/img3.jpg";
import img4 from "../assets/photos/img4.jpg";
import img5 from "../assets/photos/img5.jpg";
import img6 from "../assets/photos/img6.jpg";

export const Gallery = [
    {
        url: img1,
        alt: "Étang dans les arbres avec un abris"
    },
    {
        url: img2,
        alt: "Canard sur l'eau"
    },
    {
        url: img3,
        alt: "Étang dans les arbres"
    },
    {
        url: img4,
        alt: "Pêcheur pensif regardant la surface de l'eau d'un étang assis sur son banc"
    },
    {
        url: img5,
        alt: "Deversoir du barrage"
    },
    {
        url: img6,
        alt: "Des pêcheurs autour d'un étang avec des tables de picnic"
    }
];
