<template>
    <div class="day">
        <h2>{{fullDate}}</h2>
        <div class="tag" :class="{
            closed: isClosed(date)
        }">{{openingStatus}}
        </div>
        <p v-if="!isClosed(date)">De 8h à 18h</p>
        <p v-else>Toute la journée</p>
    </div>
</template>

<script>
    import planningMixin from "../../mixins/planning.mixin";

    export default {
        name: "Day",
        mixins: [planningMixin],
        props: {
            date: {
                type: Date,
                required: true
            },
        },
        computed: {
            fullDate () {
                return this.$props.date.getDate() === (new Date()).getDate() ? "Aujourd’hui" : "Demain"
            },
            openingStatus () {
                return this.isClosed(this.$props.date) ? "Fermé" : "Ouvert";
            }
        }
    }
</script>

<style scoped lang="scss">
    .day {
        padding: 16px;
        position: relative;
        display: grid;
        grid-template-rows: min-content min-content;
        grid-template-columns: auto 1fr;
        grid-gap: 8px;
        align-items: center;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #d0d0d0;
        flex-grow: 1;

        h2 {
            margin: 0;
            grid-column: span 2;
        }

        p {

            margin: 0;
        }

        .tag {
            padding: 4px 8px;
            background: #1a68ff;
            color: white;
            border-radius: 4px;
            width: min-content;

            &.closed {
                background: #eb004b;
            }
        }
    }
</style>
