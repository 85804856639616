<template>
    <div class="helpline">
        <slot></slot>
    </div>
</template>

<script>
    export default {
      name: 'Helpline'
    }
</script>

<style lang="scss">
    .helpline {
        background: transparentize(#1a68ff, 0.87);
        color: #1a63d9;
        padding: 20px;
        border-radius: 8px;
        margin: 0 20px 20px 20px;
        line-height: 24px;
        p {
            margin: 0 0 8px 0;
            &:last-of-type {
                margin: 0;
            }
        }

        a {
            color: #1a68ff;
        }
    }
</style>
