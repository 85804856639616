import Vue from 'vue';
import Router from 'vue-router';
import Loader from '../components/Loader';
import route404 from './routes/404';
Vue.use(Router);


const default_async_params = {
    loading: Loader,
    // A component to use if the load fails
    error: Loader,
    // Delay before showing the loading component. Default: 200ms.
    delay: 0,
    // The error component will be displayed if a timeout is
    // provided and exceeded. Default: Infinity.
    timeout: 3000
};


const router = new Router({
  mode: 'history',
  routes: [
      {
          path: '/',
          name: 'Accueil',
          meta: {
              title: `Nos formules de pêche à la truite en étang — Moulin Hubeau`,
              metaTags: [
                  {
                      name: 'description',
                      content: 'Découvrez nos formules de pêche adaptée à vos envies'
                  },
                  {
                      property: 'og:description',
                      content: 'Découvrez nos formules de pêche adaptée à vos envies'
                  }
              ]
          },
          component: () => ({
              // The component to load (should be a Promise)
              component: import('./routes/Home'),
              // A component to use while the async component is loading
              ...default_async_params
          })
      },

      {
          path: '/atelier',
          name: 'Atelier',
          meta: {
              title: `Découvrez les produits à base de truite de notre atelier — Moulin Hubeau`,
              metaTags: [
                  {
                      name: 'description',
                      content: 'Découvrez nos formules de pêche adaptée à vos envies'
                  },
                  {
                      property: 'og:description',
                      content: 'Découvrez nos formules de pêche adaptée à vos envies'
                  }
              ]
          },
          component: () => ({
              // The component to load (should be a Promise)
              component: import('./routes/atelier'),
              // A component to use while the async component is loading
              ...default_async_params
          })
      },
      {
          path: '/preparez-votre-visite',
          name: 'preparez-votre-visite',
          meta: {
              title: `Préparez votre visite sur nos étangs de pêche à la truite — Moulin Hubeau`,
              metaTags: [
                  {
                      name: 'description',
                      content: 'Prenez connaissances de ce que nous vous conseillons comme matériel pour passer une bonne journée.'
                  },
                  {
                      property: 'og:description',
                      content: 'Prenez connaissances de ce que nous vous conseillons comme matériel pour passer une bonne journée.'
                  }
              ]
          },
          component: () => ({
              // The component to load (should be a Promise)
              component: import('./routes/PreparezVisite'),
              // A component to use while the async component is loading
              ...default_async_params
          })
      }
      ,
      {
          path: '/horaire-et-acces',
          name: 'horaire-et-acces',
          meta: {
              title: `Calendrier d'ouverture — Moulin Hubeau`,
              metaTags: [
                  {
                      name: 'description',
                      content: 'Consultez le calendrier d’ouverture pour planifier votre journée.'
                  },
                  {
                      property: 'og:description',
                      content: 'Consultez le calendrier d’ouverture pour planifier votre journée.'
                  }
              ]
          },
          component: () => ({
              // The component to load (should be a Promise)
              component: import('./routes/horaire-et-acces'),
              // A component to use while the async component is loading
              ...default_async_params
          })
      },
      {
          // will match everything
          path: '*',
          name: 'not-found',
          meta: {
              title: `Moulin Hubeau — 404`,
              metaTags: []
          },
          component: route404
      }
  ]
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router;
