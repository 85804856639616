<template>
    <div class="ephemeride">
        <Day :date="today"></Day>
        <Day :date="tomorrow"></Day>
    </div>
</template>

<script>
    import Day from "./Day"
    export default {
        name: "Ephemeride",
        components: {Day},
        data() {
            return {
                today: new Date()
            }
        },
        computed: {
            tomorrow() {
                const tomorrowDate = new Date(this.today);
                tomorrowDate.setDate(tomorrowDate.getDate() + 1);
                return tomorrowDate;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../global-style/_variables.scss";
.ephemeride {
    display: flex;
    width: max-content;
    flex-wrap: wrap;
    justify-content: center;
    .day:first-of-type {
        margin-right: 20px;
    }

    @media screen and (max-width: $second-brake) {
        width: 100%;
        .day {
            width: 100%;
            &:first-of-type {
                margin-right: 0;
                margin-bottom: 8px;
            }
        }
    }
}
</style>
