<template>
    <div class="formule" :id="formule.slug">
        <div class="title">
            <img :src="formule.image.url" :alt="formule.image.alt">
            <div class="gradient">
                <h2>{{formule.name}}</h2>
            </div>
        </div>
        <div class="content">
            <p class="description">{{formule.description}}</p>
            <div class="options">
                <div class="option" v-for="option in formule.options">
                    <div class="line" v-if="option.name"><h3>{{option.name}}</h3></div>
                    <div class="line" v-if="option.timeInterval"><img alt="Horloge" src="../assets/horloge.svg"><span>{{option.timeInterval}}</span></div>
                    <div class="line" v-if="option.price"><img alt="Pièce de monnaie" src="../assets/pieces.svg"><span>{{option.price}}</span></div>
                    <div class="line" v-if="option.fishNumber"><img alt="Poissons" src="../assets/poisson.svg"><span>{{option.fishNumber}}</span></div>
                </div>

            </div>
            <Helpline v-if="formule.helpLine" v-html="formule.helpLine" />
        </div>
    </div>
</template>

<script>
    import ImageCover from './ImageCover'
    import Helpline from './Helpline'

    export default {
        name: 'Formule',
        props: ["formule"],
        components: {Helpline, ImageCover}
    }
</script>

<style scoped lang="scss">
    @import "../global-style/variables";

    .formule {
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #d0d0d0;
        overflow: hidden;
        .title {
            display: flex;
            align-items: flex-end;
            flex-grow: 0;
            position: relative;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                min-height: 100%;
            }
            .gradient {
                background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                flex-grow: 1;
                padding: 20px;
                margin-top: 100px;
                z-index: 1;
                h2 {
                    display: block;
                    color: white;
                    font-size: 40pt;
                    margin: 0;
                    font-weight: 700;
                }
            }
        }
        .content {
            .description {
                padding-left: 20px;
                padding-right: 20px;
                font-size: 14pt;
                font-family: "Source Serif", serif;
                line-height: 1.4;
                font-weight: 500;
            }
            .options {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                .option {
                    flex-grow: 1;
                    border-right: 1px solid #d0d0d0;
                    border-top: 1px solid #d0d0d0;
                    h3 {
                        margin: 0;
                        font-size: 18pt;
                    }
                    .line {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 15px 20px;
                        @media screen and (max-width: $second-brake) {
                            padding: 8px 20px;
                        }
                        //border-top: 1px solid #eaebec;
                        img {
                            height: 25px;
                        }
                        span {
                            flex-grow: 1;
                            //text-align: right;
                            margin-left: 20px
                        }
                        &:first-child {
                            padding-top: 25px;
                        }
                        &:last-child {
                            padding-bottom: 25px;
                        }
                    }

                }
                .option:nth-child(even) {

                    border-right: none;
                }
                .option:nth-child(odd):last-child {
                    border-right: none;
                    grid-column: 1 / 3;
                }

                @media screen and (max-width: $second-brake) {
                    grid-template-columns: repeat(1, 1fr);
                    .option {

                        border-right: none;
                    }
                    .option:nth-child(odd):last-child {
                        border-right: none;
                        grid-column: 1 / 1;
                    }
                }
            }
        }
    }
</style>
