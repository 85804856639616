<template>
    <div class="formuleFilter">
        <div class="content">
            <div class="group">
                <div class="groupTitle">Combien de personnes serez-vous ?</div>
                <Segment :tabs="[
                { label: 'Moins de 5 environ', value: 5},
                { label: 'Entre 5 et 50', value: 50},
                { label: 'Plus de 50', value: 60}
                ]"
                         @selection="numberOfVisitorsSelection"/>
            </div>
            <div class="group">
                <div class="groupTitle">Y aura-t-il des enfants ?</div>
                <Segment :tabs="[
                    { label: 'Oui', value: true},
                    { label: 'Non', value: false}
                ]"
                         @selection="childPresenceSelection"/>
            </div>
            <div class="buttonZone">
                <button v-if="!$data.pristine" @click="clearFilter">Enlever les filtres</button>
                <button class="raised" @click="applyFilters">Conseillez-moi</button>
            </div>
        </div>
        <Helpline>
            Si vous ne trouvez pas ce qu'il vous faut, n'hésitez pas à nous appeler au
            <a href="tel:+33241890628">02 41 89 06 28</a>.
        </Helpline>
    </div>
</template>

<script>
    import Helpline from './Helpline'
    import Segment from './Segment'

    export default {
      name: 'FormuleFilter',
      components: {Helpline, Segment},
        data () {
            return {
                childPresence: true,
                numberOfVisitors: -1,
                pristine: true
            }
        },
        methods: {
            childPresenceSelection (selection) {
                this.$data.childPresence = selection.value
            },
            numberOfVisitorsSelection(selection) {
                this.$data.numberOfVisitors = selection.value
            },
            applyFilters() {
                this.$data.pristine = false;
                this.$emit("applyFilter", {
                    childPresence: this.$data.childPresence,
                    numberOfVisitors: this.$data.numberOfVisitors
                });
            },
            clearFilter() {
                this.$data.childPresence = true;
                this.$data.numberOfVisitors = -1;
                this.$data.pristine = true;
                this.$emit("resetSelection");
                this.$emit("applyFilter", this.$data);
            }
        }
    }
</script>

<style scoped lang="scss">
    .formuleFilter {
        grid-area: filter;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #d0d0d0;
        overflow: hidden;
        display: grid;
        grid-template: "content" 1fr "helpline" auto;
        .content {
            padding: 20px;
            display: grid;
            grid-gap: 20px;
            .group {
                display: grid;
                grid-gap: 10px;
                .groupTitle {
                    font-size: 12pt;
                    font-weight: 500;
                }
            }

            .buttonZone{
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                button {
                    margin-left: 10px;
                }
            }
        }
    }
</style>
