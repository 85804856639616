<template>
    <h1>loader</h1>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>

<style scoped>

</style>
