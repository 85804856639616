<template>
    <div class="imageGallery">
        <div ref="scroller" class="imageGallery-slide-container">
            <img
                v-for="(slide, i) in slides"
                :src="slide.url"
                class="slide"
                :ref="`slide${i}`"
                :alt="slide.alt"
            />
        </div>
        <button
            class="imageGallery-button imageGallery-button-prev"
            @click="() => goToSlide(-1)"
        >
            <img src="../assets/left-arrow.svg">
        </button>

        <button
            class="imageGallery-button imageGallery-button-next"
            @click="() => goToSlide(1)"
        >
            <img src="../assets/right-arrow.svg">
        </button>
    </div>
</template>

<script>
    import {Gallery} from "/store/gallery";
    import {debounce, smoothScroll} from "../lib/ScrollKit";


    export default {
        name: 'ImageGallery',
        data () {
            return {
                slides: Gallery.map(slide => ({...slide, fullscreen: false})),
                selectedSlide: 0
            }
        },
        mounted () {
            const scroller = this.$refs.scroller;
            scroller.addEventListener('scroll', debounce(() => {
                this.selectedSlide = Math.round((scroller.scrollLeft / scroller.scrollWidth) * this.slides.length)
            }, 200))
        },
        methods: {
            toggleFullscreen (i) {
                this.slides[i].fullscreen = !!!this.slides[i].fullscreen;
            },
            goToSlide(direction) {
                console.log(this.$refs[`slide${this.selectedSlide + direction}`]);
                if(this.$refs[`slide${this.selectedSlide + direction}`]) {
                    /*smoothScroll(
                        this.$refs[`slide${this.selectedSlide + direction}`][0],
                        0,
                        true
                    )*/
                    this.$refs[`slide${this.selectedSlide + direction}`][0].scrollIntoView(
                        {
                            behavior: "smooth",
                            inline: "start",
                            block: "center"
                        }
                    )
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../global-style/_variables.scss";

    .imageGallery {
        width: 100vw;
        position: relative;
        &-slide-container {
            width: 100%;
            height: 400px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            z-index: 10;
            user-select: none;
            overflow: auto;
            scrollbar-width: none;
            scroll-snap-type: x mandatory;
            position: relative;
            box-sizing: border-box;

            &::-webkit-scrollbar {
                display: none;
            }
            &::after {
                content: "";
                display: block;
                min-width: 20px;
                width: 20px;
                height: 100%;
            }

            @media screen and (max-width: $first-brake) {
                height: 300px;
                &::after {
                    min-width: 10px;
                    width: 10px;
                }
            }

            @media screen and (max-width: $second-brake) {
                height: 200px;
            }

            .slide {
                height: 100%;
                object-fit: contain;
                margin-left: 20px;
                border-radius: 10px;
                -webkit-user-select: none;
                -moz-user-select: none;
                user-select: none;
                box-sizing: border-box;
                scroll-snap-align: start;
                scroll-margin-left: 20px;

                @media screen and (max-width: $second-brake) {
                    margin-left: 10px;
                    scroll-margin-left: 10px;
                    &:last-child {
                        margin-right: 10px;
                        scroll-margin-right: 10px;
                    }
                }
            }
        }

        &-button {
            position: absolute;
            display: block;
            line-height: 0;
            border-radius: 24px;
            padding: 12px;
            height: 16px;
            width: 16px;
            z-index: 10000;
            box-sizing: content-box;
            background: white;
            margin: 16px auto auto;
            bottom: 8px;

            &:hover {
                background: #d7d7d7 !important;
            }

            &-next {
                right: 28px;

                @media screen and (max-width: $second-brake) {
                    right: 18px;
                }
            }

            &-prev {
                left: 28px;

                @media screen and (max-width: $second-brake) {
                    left: 18px;
                }
            }
        }
    }
</style>
