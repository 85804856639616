Date.prototype.getWeek = function() {
    const date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
};

export default {
    methods: {
        isClosed(date) {
            if(date.getMonth() === 4 && date.getDate()=== 1) return false;
            if(date.getMonth() === 4 && date.getDate()=== 8) return false;
            if(date.getDay() === 3) return true;
            if((date.getMonth() >= 9 || date.getMonth() <= 2) && date.getDay() === 0) return true;
            if(date.getWeek() < 4 || (date.getWeek() === 4 && date.getDay() <= 5)) return true;
            if(date.getWeek() > 45) return true;
            return false;
        },
        isToday(date) {
            const today = new Date();
            return date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
        }
    }
}
