<template>
    <div class="segment">
        <div class="wrapper">
            <div :class="`tab ${$data.selectedItem === tab.label ? 'selected' : '' }`" v-for="tab in $attrs.tabs" @click="() => tabClick(tab)">{{tab.label}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Segment',
        params: ['tabs'],
        data () {
            return {
                selectedItem: null,
                index: false
            }
        },
        methods: {
            tabClick (tab) {
                this.$data.selectedItem = tab.label;
                this.$emit("selection", tab);
            },
            resetSelection() {
                this.$data.selectedItem = null;
            }
        },
        created() {
            this.$parent.$on('resetSelection', this.resetSelection)
        }
    }
</script>

<style scoped lang="scss">
    .segment {
        border: 2px solid #1a68ff;
        border-radius: 8px;
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        margin-bottom: 5px;
        margin-top: 5px;
        overflow: hidden;
        .wrapper {
            display: flex;
            flex: 1;
            .tab {
                align-items: center;
                color: #1a68ff;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: center;
                padding: 10px;
                position: relative;
                text-align: center;
                transition: background .2s, color .2s;
                font-weight: 500;
                cursor: pointer;
                &:hover {
                    background: rgba(26, 104, 255, .1);
                }
                &.selected {
                    background: #1a68ff;
                    color: white;
                }
            }
        }
    }
</style>
