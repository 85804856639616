import Vue from 'vue'
import App from './App'
import router from './router'
import Fragment from "vue-fragment";
import VueMatomo from 'vue-matomo';
Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: 'https://stats.moulinhubeau.fr',
    siteId: 1,
    // Enables automatically registering pageviews on the router
    router: router,
    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: true,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 15,
});

Vue.config.productionTip = false;

Vue.use(Fragment.Plugin);

new Vue({
    router,
    render: λ => λ(App)
}).$mount(document.getElementById("app"));

if ('serviceWorker' in navigator) {
    try {
        navigator.serviceWorker.register('./sw.js');
        console.log('Service Worker Registered');
    } catch (error) {
        console.log('Service Worker Register Failed');
    }
}
