<template>
    <div class="imageCover" :style="`height: ${height}`">

    </div>
</template>

<script>
 export default {
   name: 'ImageCover',
   props: ['url', 'height']
 }
</script>

<style scoped lang="scss">
    .imageCover {
        grid-column: 1 / 4;
        background: url(../assets/cover.jpg) center -301px;
        background-size: cover;
        height: 400px;
        z-index: 10;
    }
</style>
