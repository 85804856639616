<template>
    <main>
        <p class="slogan">Désolé mais il n'y as rien ici...</p>
        <router-link to="/" class="main-link">Retour à la page d'accueil</router-link>
    </main>
</template>

<script>
    import ImageCover from '/components/ImageCover'
    import Helpline from '/components/Helpline'
    import FormuleFilter from '/components/FormuleFilter'
    import Formule from '/components/Formule'
    import ImageGallery from "/components/ImageGallery"
    import {Gallery} from "/store/gallery";
    import Ephemeride from "../../components/Ephemeride/Ephemeride";

    export default {
        name: 'Home',
        components: {
            Ephemeride,
            Formule,
            FormuleFilter,
            Helpline,
            ImageCover,
            ImageGallery,
        },
        created () {
            (async () => {
                this.offerStore = (await import("/store/formules.js")).OfferStore;
                this.offers = this.offerStore.offers;
            })();
        },
        data () {
            return {
                window: window,
                Gallery,
                offers: [],
                offerStore: []
            }
        },
        methods: {
            filterList (filters) {
                this.offers = this.offerStore.offers.filter((formule) => {
                    return formule.maxNumberVisitor(filters.numberOfVisitors) &&
                        (formule.forChildren === filters.childPresence ||
                            filters.childPresence && (formule.childrenOffersComplementary === filters.childPresence))
                });
            }
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "../../global-style/_variables.scss";


    main {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        min-height: 50vh;

        .slogan {
            font-family: "Source Serif Italic", serif;
            font-size: 2rem;
            line-height: 26pt;
            font-weight: 500;
        }

        .main-link {
            font-size: 1.2rem;
        }
    }
</style>
